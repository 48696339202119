$default-breakpoint: 900px;

$brand-blue: #25255e;
$brand-cyan: #2fcca1;
$brand-cyan-light: #d2f5eb;
$brand-red: #e65f2b;
$gray: #2d2d2d;
$mid-dark-gray: #868686;
$light-gray: #7e7e7e;
$mid-gray: #dadada;
$very-light-gray: #f9f8f8;
$light-gray-2: #707070;
$black: #121212;
$white: #fff;
$cyan: #f5fafd;
$light-gray-100: #eaecef;
$linkClr: #0000ff;
$light-gray-1: #fafafa;

$blue: #2f74ac;
$light-blue: #f0f8ff;
$azure-white: #d5def0;
$indigo-dye: #0052b2;
$madison: #0c2662;
$gallery: #f0f0f0;
$stratos: #01014b;
$ebony-clay: #23283f;
$victoria: #3c3c88;
$cornflower-blue: #549de6;
$red: #ff4343;
$state-brown: #c57636;

$theme-navy: #25255e;
$theme-green: #2fcca1;
$theme-blue: #a0d6ef;
$theme-cyan: #aceded;
$theme-honeydew: #ebfaf6;
$theme-yellow: #fef5c5;
$theme-cinderalla: #fde3d1;
$theme-orange: #ffd9a7;
$theme-pink: #ffbbb9;
$theme-kobi: #eca8d4;
$theme-vanilla: #eecee6;

$disabled-overlay: hsla(0, 0%, 0%, 0.4);
$opaque-bg: hsla(0, 0%, 75%, 0.8);
$light-opaque-bg: hsla(0, 0%, 90%, 0.8);
$box-shadow-default: 2px 2px 10px rgba(0, 0, 0, 0.2);
$correct-green: #2fcca1;
$incorrect-orange: #ffbb69;

$success: #2fcca1;
$error: #ff7069;
$warning: #ffbb69;
$info: #549de6;

$error-light-10: #fbeceb;
$warning-light-10: #fbf4ec;

$express-linker-inner: #00d66f;
$yello-bg: #ffd814;

$header-height: 54px;
$breadcumb-height: 64px;
$mobile-header-height: 48px;
$spacing: 20px;
$container-width: 800px;
$student-sidebar: 300px;
$accordion: #dcf5ee;
